/*import React, { useState } from 'react';
import Header from "../../Components/Header.jsx";

export default function InicioRecibo() {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChange = (event) => {
        const values = Array.from(event.target.selectedOptions, (option) => option.value);
        setSelectedOptions(values);
    };
  return (
    <div className='container'>
        <Header textHeader="Recibo de Cobro!!!"/>
        <form onSubmit>
        <div className='anchos shadow rounded-4 mb-12 col-sm-12' style={{color:"#2C3F81"}}>
            <h4>Introduce los siguientes datos</h4>
        <div className="row">            
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Nombre <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="nombre_encuesta"
                  className="form-control"
                  placeholder="Nombre"
                  //value={formData.nombre_encuesta}
                  //onChange={handleChange}
                  required
                />
              </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                    Cobrador <span className="text-danger"> *</span>
                </label>
                <select value={selectedOptions} onChange={handleChange} >
                    <option value="apple">Jhoseline Apaza</option>
                    <option value="banana">Fernando Portugal</option>
                    <option value="orange">Edson Guzman</option>
                    <option value="grape">Bryan Ali</option>
                </select>
               
            </div>
              <div className="col-md-4 py-md-4">
                <label className="text-info">
                  Primer Apellido<span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  name="apellido_encuesta"
                  className="form-control"
                  placeholder="Primer Apellido"
                  //value={formData.apellido_encuesta}
                  //onChange={handleChange}
                  required
                />
              </div>
            
          </div>
        </div>
        <div className='container col-xl-4'>   
                <button
                    className="btn btn-primary mt-2 w-100"
                    type="submit"
                    style={{color:"white", font:'LemonMilk', fontSize:"20px"}}
                >
                    Siguiente
                </button>
        </div>
        </form> 
    </div>
  )
}*/

import React, { useState } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";  // Importa el plugin para tablas
import logo from "./logo.png"; // Asegúrate de que tu logo esté en la carpeta "src"
import { NumerosALetras } from 'numero-a-letras'; // Importa la librería para convertir números a letras
import './Form.css';
import axios from 'axios';

const InicioRecibo = () => {
  let num_recibo = 0;
  const [response, setResponse] = useState('');
  const [formData, setFormData] = useState({
    cobrador: "",
    monto: "",
    moneda: "bolivianos",
    nroRecibo: "",
    cliente: "",
    concepto: "",
    nroPrestamo: "",
    ciCliente: "" // Añadimos el campo para el CI del cliente
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  async function obtenerDatos() {
    try {
      const respuesta = await fetch(`${process.env.REACT_APP_API_BACKEND}/recibo`,
        {
          method: "GET",
        }
      ); // Reemplaza la URL por la de tu endpoint
      if (!respuesta.ok) {
        throw new Error(`Error en la solicitud: ${respuesta.statusText}`);
      }
      const datos = await respuesta.json();
      console.log(datos); // Aquí puedes procesar el objeto JSON recibido
      num_recibo = parseInt(datos.count) + 1;
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  }

  async function guardarDatos(data) {
    const datos = {
      numero_recibo: parseInt(num_recibo),
      fecha: new Date().toLocaleDateString(),
      cobrador: data.cobrador,
      monto: data.monto,
      moneda: data.moneda,
      cliente: data.cliente,
      ci_cliente: data.ciCliente,
      concepto: data.concepto,
      num_prestamo: data.nroPrestamo
    };
    console.log(datos);
    await axios.post( `${process.env.REACT_APP_API_BACKEND}/recibo`, datos)
    .then((response) => {
        console.log('Respuesta de la API:', response.datos);
        setResponse(response.datos);
    })
      .catch((error) => {
        console.error('Error al enviar los datos a la API:', error);
      });


  }

  const handleSubmit = async (e) => {
    num_recibo = num_recibo + 1;
    //await obtenerDatos ();
    e.preventDefault();
    generatePDF(formData);
    await guardarDatos(formData);
  };

  const generatePDF = async (data) => {
    const doc = new jsPDF();

    // Convertir monto a texto
    //const montoEnLetras = toCurrency(data.monto, { currency: data.moneda });
    const montoEnLetras = NumerosALetras(data.monto);
    let montoDolares = 0, montobs = 0;
    let montoFinal = "";
    if(data.moneda === "bolivianos"){
      montobs = data.monto;
      montoFinal = montoEnLetras.replace(/Pesos/g, "") + "bolivianos";
    } else{
      montoDolares = data.monto;
      montoFinal = montoEnLetras.replace(/Pesos/g, "") + "dólares";
    }
    montoFinal = montoFinal.replace(/M.N./g, "");

    // Agregar logo
    doc.addImage(logo, "PNG", 10, 10, 30, 30); // Ajusta el tamaño y la posición del logo

    // Títulos y datos
    doc.setFontSize(16);
    doc.text("*******************************************************", 50, 15); 
    doc.text(`Recibo de Caja No: ${num_recibo}`, 70, 20); // Título
    doc.text("*******************************************************", 50, 28); 

    doc.text(`Bs.:  ${montobs}`, 90, 35);
    doc.text(`$us.: ${montoDolares}`, 90, 40);
    
    doc.setFontSize(12);
    doc.setLineDash([2, 1]);
    doc.line(10, 45, 200, 45); // Línea en la parte inferior
    doc.text(`Fecha: ${new Date().toLocaleDateString()}`, 10, 50);
    doc.line(10, 52, 200, 52); // Línea en la parte inferior
    doc.setLineDash([]);
    doc.text(`Cliente: ${data.cliente}`, 10, 60);
    doc.text(`Número de Préstamo: ${data.nroPrestamo}`, 10, 70);
    //doc.text(`Monto: ${data.monto} ${data.moneda}`, 10, 70);
    doc.text(`Monto en Letras: ${montoFinal}`, 10, 80); // Monto en letras
    //doc.text(`Número de Recibo: ${data.nroRecibo}`, 10, 90);
    //doc.text(`Cliente: ${data.cliente}`, 10, 100);
    //doc.text(`CI Cliente: ${data.ciCliente}`, 10, 110); // CI del cliente
    doc.text(`Por concepto de: ${data.concepto}`, 10, 90);
    

    // Agregar una línea separadora
    doc.setLineWidth(0.5);
    doc.line(10, 100, 200, 100); // Línea en la parte inferior

    // Agregar una tabla 2x2
   /* const tableData = [
      ["Bs.", montobs], // Fila 1
      ["$us.", montoDolares], // Fila 2
    ];*/

    /*doc.autoTable({
      startY: 150, // La tabla empezará en la Y=150 (justo después de la línea separadora)
      startX: 100,
      //head: [["Campo", "Valor"]],
      body: tableData,
      theme: "grid", // Utiliza el tema "grid" para una tabla con líneas
      margin: { top: 50 },
      columnStyles: {
        0: { cellWidth: 60 }, // Primera columna más ancha
        1: { cellWidth: 100 }, // Segunda columna más ancha
      },
    });*/

    // Agregar "Entregado Conforme" y "Recibí Conforme"
    //const yPosition = doc.autoTable.previous.finalY + 20; // Determina la posición Y de la última tabla
    doc.text("Entregado Conforme:", 10, 140); // Textos
    doc.text(data.cliente, 10, 150); // Nombre del cliente
    doc.text(`CI: ${data.ciCliente}`, 10, 160); // CI del cliente

    doc.text("Recibí Conforme:", 100, 140); // Textos
    doc.text(data.cobrador, 100, 150); // Nombre del cobrador

    // Descargar el PDF
    doc.save(`${num_recibo}_recibo.pdf`);
    console.log(num_recibo);
  };

  return (
    <form onSubmit={handleSubmit} className="form-container">
      <div className="form-group">
        <label htmlFor="fecha">Fecha</label>
        <input type="date" id="fecha" value={new Date().toISOString().split("T")[0]} disabled />
      </div>

      <div className="form-group">
        <label htmlFor="cobrador">Cobrador</label>
        <select
          id="cobrador"
          name="cobrador"
          value={formData.cobrador}
          onChange={handleChange}
        >
          <option value="">Selecciona un cobrador</option>
          <option value="Jhoseline Apaza">Jhoseline Apaza</option>
          <option value="Fernando Portugal">Fernando Portugal</option>
          <option value="Edson Guzman">Edson Guzman</option>
          <option value="Bryan Ali">Bryan Ali</option>
          {/* Agrega más nombres de cobradores aquí */}
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="monto">Monto</label>
        <input
          type="number"
          id="monto"
          name="monto"
          value={formData.monto}
          onChange={handleChange}
          step="0.01"
        />
      </div>

      <div className="form-group">
        <label htmlFor="moneda">Moneda</label>
        <select
          id="moneda"
          name="moneda"
          value={formData.moneda}
          onChange={handleChange}
        >
          <option value="bolivianos">Bolivianos</option>
          <option value="dolares">Dólares</option>
        </select>
      </div>

      <div className="form-group">
        <label htmlFor="cliente">Cliente</label>
        <input
          type="text"
          id="cliente"
          name="cliente"
          value={formData.cliente}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="ciCliente">C.I. Cliente</label>
        <input
          type="text"
          id="ciCliente"
          name="ciCliente"
          value={formData.ciCliente}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="concepto">Concepto</label>
        <input
          type="text"
          id="concepto"
          name="concepto"
          value={formData.concepto}
          onChange={handleChange}
        />
      </div>

      <div className="form-group">
        <label htmlFor="nroPrestamo">Número de Préstamo</label>
        <input
          type="text"
          id="nroPrestamo"
          name="nroPrestamo"
          value={formData.nroPrestamo}
          onChange={handleChange}
        />
      </div>

      <button type="submit">Generar Recibo</button>
    </form>
  );
};

export default InicioRecibo;
